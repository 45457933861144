import get from 'lodash/get';

export const trim = (string, length) =>
  `${string}`.length > length ? `${`${string}`.substr(0, length - 3)}...` : string;

export const zeroPad = (value, length = 2) => `${value}`.padStart(length, '0');

export const encodeUrl = string => {
  let sanitizedUrl = `${string}`.replace(/%/g, '%25');
  sanitizedUrl = `${sanitizedUrl}`.replace(/\//, '%2F');
  return sanitizedUrl;
};

export const sanitizeUrl = string => {
  const space = `${string}`.replace(/\s+/gi, '+');
  return `${space}`.replace(/%/, 'phantram');
};

export const decodeUrl = string => {
  const deSanitize = decodeURIComponent(string);
  return deSanitize.replace(/phantram/, '%').replace(/\+/gi, ' ');
};

export const parseUserFilterString = string => {
  const sanitizedFilter = string.replace(/&utm.*/, '');
  const filters = `${sanitizedFilter}`.split('&');
  return {
    categories: get(filters, '0', ''),
    types: get(filters, '1', ''),
    brands: get(filters, '2', ''),
    methods: get(filters, '3', ''),
    pill: get(filters, '4', ''),
    district: get(filters, '5', ''),
    wards: /wards/i.test(get(filters, '6')) ? get(filters, '6') : '',
  };
};

export const deAccent = string =>
  `${string}`.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const literalStringComparison = (param1, param2) =>
  JSON.stringify(param1) === JSON.stringify(param2);
