import {
  createDeal,
  updateDeal,
  deleteDeal,
  searchDeal,
  adminDeal,
  adminSearch,
  filter,
  quickSearch,
} from '../api/deal';
import { createApiRequest } from './helpers';
import createAction from './createAction';
import ACTIONS from '../constants/actions';

export const resetDealAction = createAction(ACTIONS.DEAL.RESET);
export const createDealApi = createApiRequest(createDeal, true, ACTIONS.DEAL.CREATE);
export const updateDealApi = createApiRequest(updateDeal, true, ACTIONS.DEAL.UPDATE);
export const deleteDealApi = createApiRequest(deleteDeal, true, ACTIONS.DEAL.DELETE);
export const adminDealApi = createApiRequest(adminDeal, true, ACTIONS.DEAL.ADMIN);
export const adminSearchApi = createApiRequest(
  adminSearch,
  true,
  ACTIONS.DEAL.ADMIN_SEARCH,
);
export const setAdminSearchText = createAction(
  ACTIONS.DEAL.SET_ADMIN_SEARCH_TEXT,
  true,
);
export const fetchDealDetail = createApiRequest(
  searchDeal,
  true,
  ACTIONS.DEAL.FETCH_DETAIL,
);
export const dealDetailApi = createApiRequest(
  searchDeal,
  true,
  ACTIONS.CUSTOMER.DEAL_DETAIL,
);
export const setSameCategoryDeals = createApiRequest(
  filter,
  true,
  ACTIONS.CUSTOMER.DEAL_IN_SAME_CATEGORY,
);
export const setSearchBarDeals = createAction(
  ACTIONS.CUSTOMER.SET_SEARCH_BAR_DEALS,
  true,
);
export const pageNumberAction = createAction(ACTIONS.CUSTOMER.PAGE_NUMBER, true);
export const searchTextAction = createAction(ACTIONS.CUSTOMER.SEARCH_TEXT, true);
export const configsAction = createAction(ACTIONS.DEAL.CONFIGS, true);
export const setUserDealsFiltered = createApiRequest(
  filter,
  true,
  ACTIONS.CUSTOMER.FILTER_DEALS,
);
export const setUserDealsSearched = createApiRequest(
  quickSearch,
  true,
  ACTIONS.CUSTOMER.QUICK_SEARCH,
);
export const submitUserDealsSearched = createAction(
  ACTIONS.CUSTOMER.SUBMIT_QUICK_SEARCH,
  true,
);
export const clearUserDealsSearched = createAction(ACTIONS.CUSTOMER.CLEAR_QUICK_SEARCH);
