/* eslint-disable dot-notation */
import axios from './config';
import { createHeaders } from '../utils/helpers';
import URL from '../constants/url';

// admin
export const createDeal = (data, token) =>
  axios.post(URL.DEAL, data, { ...createHeaders(token) });
export const deleteDeal = (id, token) =>
  axios.delete(`${URL.DEAL}/${id}`, { ...createHeaders(token) });
export const updateDeal = (data, token) =>
  axios.put(`${URL.DEAL}/${data.id}`, data, { ...createHeaders(token) });
export const adminDeal = (data, token) =>
  axios.post(URL.DEAL_ADMIN, data, { ...createHeaders(token) });
export const adminSearch = (data, token) =>
  axios.post(URL.ADMIN_SEARCH, data, { ...createHeaders(token) });
// customer
export const dealConfigs = () => axios.get(URL.CONFIGS);
export const searchDeal = data => {
  let url = URL.SLUG;
  const isSlug = Number(data.search('-')) > -1;
  if (!isSlug) {
    url = URL.DEAL;
  }
  return axios.get(`${url}/${data}`);
};
export const filter = data => axios.get(`${URL.FILTER}${data}`);
export const quickSearch = data => axios.get(`${URL.SEARCH}${data}`);
