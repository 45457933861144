import React from 'react';
import { objectOf, any, bool, func } from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { navigate } from 'gatsby';
import moment from 'moment';
import get from 'lodash/get';
import { uuid } from 'uuidv4';
import classNames from 'classnames';
import Typo from '@material-ui/core/Typography';
import ChevronRight from '@material-ui/icons/ChevronRight';
import PaymentIcon from '../../images/pay.png';
import TimerIcon from '../../images/schedule.png';
import COLORS from '../../constants/colors';
import LAYOUT from '../../constants/layout';
import TIME from '../../constants/time';
import Logo from '../../svgs/logo.svg';
import CustomImage from '../customImage';
import HomeGrid from '../HomeGrid';
import resolveImageUrl from '../../utils/image';
import { dateDeadline } from '../../utils/helpers';
import { sanitizeUrl, trim } from '../../utils/string';
import ROUTER from '../../constants/router';

const useStyles = makeStyles(
  ({ spacing, typography, palette, shape, breakpoints, transitions }) => ({
    root: {
      position: 'relative',
      margin: spacing(1, 0.5),
      borderRadius: spacing(1),
      boxShadow: COLORS.SHADOW_STANDARD,
      display: 'flex',
      flexDirection: 'column',
      width: '45%',
      minWidth: spacing(LAYOUT.CARD_WIDTH),
      maxWidth: spacing(LAYOUT.SMALL_CARD_WIDTH),
      background: palette.background.paper,
      transition: transitions.easing.custom,
      minHeight: spacing(37),
      overflow: 'hidden',
    },
    dummy: {
      visibility: 'hidden',
    },
    cardLarge: {
      minWidth: spacing(LAYOUT.MEDIUM_CARD_WIDTH),
      height: spacing(51),
    },
    header: {
      position: 'relative',
      margin: 0,
    },
    pictureWrap: {
      position: 'relative',
      width: '100%',
      height: spacing(12),
      overflow: 'hidden',
    },
    pictureWrapLarge: {
      height: spacing(25),
    },
    picture: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: transitions.easing.custom,
      '&:hover': {
        opacity: 0.8,
      },
    },
    pictureDefault: {
      position: 'absolute',
      height: '110%',
      width: 'auto',
    },
    logoWrap: {
      width: spacing(4),
      height: spacing(4),
      borderRadius: shape.round,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${COLORS.WHITE}`,
      background: COLORS.WHITE,
      overflow: 'hidden',
      padding: 0,
      boxShadow: COLORS.SHADOW_STANDARD,
    },
    logoWrapLarge: {
      width: spacing(5),
      height: spacing(5),
    },
    logo: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    brand: {
      position: 'absolute',
      bottom: spacing(-1.5),
      margin: spacing(0, 1),
    },
    brandName: {
      fontSize: typography.body3.fontSize,
      fontWeight: typography.fontWeightBold,
      color: COLORS.WHITE,
      margin: spacing(0, 0.5),
      textShadow: COLORS.TEXT_SHADOW,
    },
    content: {
      padding: spacing(0.25),
    },
    title: {
      margin: spacing(1, 0.5),
    },
    titleText: {
      fontSize: typography.body2.fontSize,
      fontWeight: typography.fontWeightBold,
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    titleTextMd: {
      fontSize: typography.body2.fontSize,
    },
    titleTextLg: {
      fontSize: typography.subtitle2.fontSize,
    },
    typeRow: {
      margin: spacing(0.25),
      maxHeight: spacing(7),
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
    },
    types: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: spacing(6.5),
    },
    typeItem: {
      fontSize: spacing(1.25),
      textTransform: 'uppercase',
      margin: spacing(0.25, 0.5),
      padding: spacing(0.25, 0.5),
      border: `1px solid ${COLORS.LIGHT_GREY}`,
      borderRadius: shape.rectRadius,
      background: COLORS.SMOKE,
    },
    iconText: {
      display: 'flex',
      alignItems: 'center',
    },
    itemTextMd: {
      fontSize: typography.body3.fontSize,
    },
    itemText: {
      fontSize: typography.body2.fontSize,
    },
    cta: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: spacing(0, 1),
      justifyContent: 'center',
      cursor: 'pointer',
      userSelect: 'none',
    },
    ctaText: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: `${spacing(0.125)}px solid ${COLORS.LIGHT_GREY}`,
      padding: spacing(1, 0, 1.5),
      fontSize: typography.body2.fontSize,
      fontWeight: typography.fontWeightBold,
      color: palette.text.primary,
    },
    icon: {
      width: spacing(3),
      height: spacing(3),
    },
    iRight: {
      fill: palette.primary.main,
    },
    iconLogo: {
      width: spacing(5),
      height: spacing(5),
      marginTop: spacing(-1.1),
    },
    expireWarning: {
      position: 'absolute',
      top: spacing(2),
      right: spacing(2),
      background: palette.primary.main,
      padding: spacing(0.5, 1),
      color: COLORS.WHITE,
      borderRadius: shape.rectRadius,
    },
    warning: {
      color: COLORS.WHITE,
      letterSpacing: 1,
    },
    [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
      root: {
        margin: spacing(1),
        borderRadius: spacing(1),
        maxWidth: 'unset',
        minWidth: 'unset',
        width: spacing(LAYOUT.SMALL_CARD_WIDTH),
        height: 'auto',
      },
      cardLarge: {
        width: spacing(LAYOUT.LARGE_CARD_WIDTH),
        height: 'auto',
      },
      pictureWrap: {
        height: spacing(22),
        overflow: 'hidden',
      },
      pictureWrapLarge: {
        height: spacing(25),
      },
      logoWrap: {
        width: spacing(5),
        height: spacing(5),
      },
      logoWrapLarge: {
        width: spacing(6),
        height: spacing(6),
      },
      brand: {
        bottom: spacing(-1),
        margin: spacing(0, 2),
      },
      brandName: {
        fontSize: typography.body1.fontSize,
        margin: spacing(0.5, 1),
      },
      content: {
        padding: spacing(1.5),
      },
      titleText: {
        fontSize: typography.body1.fontSize,
      },
      titleTextMd: {
        fontSize: typography.body1.fontSize,
      },
      titleTextLg: {
        fontSize: typography.subtitle1.fontSize,
      },
      itemTextMd: {
        fontSize: typography.body2.fontSize,
      },
      itemText: {
        fontSize: typography.body1.fontSize,
      },
      expireWarning: {
        position: 'relative',
        top: 'unset',
        right: 'unset',
        background: COLORS.TRANSPARENT,
        padding: 0,
        borderRadius: 'unset',
      },
      warning: {
        color: palette.primary.main,
        padding: spacing(0, 1, 0, 0),
        letterSpacing: 0,
      },
      cta: {
        position: 'relative',
        bottom: 'unset',
        padding: spacing(0, 1),
      },
      ctaText: {
        fontSize: typography.body1.fontSize,
        padding: spacing(2, 0, 2.5),
      },
    },
  }),
);

const DealCard = ({ item, large, onAdView }) => {
  const s = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP));
  let cardStyle = classNames(s.root, large && s.cardLarge);
  const isDummy = get(item, 'dummy');
  cardStyle = classNames(cardStyle, isDummy && s.dummy);
  const dealPicture = get(item, 'picture.url');
  const brandLogo = get(item, 'brand.logo.url');
  const types = get(item, 'types', []);
  const methods = get(item, 'methods', []);
  const expire = get(item, 'expire');
  const dealTitle = get(item, 'title', '').toLowerCase();
  const brandName = get(item, 'brand.name');
  const trimLength = large
    ? LAYOUT.CARD.METHOD_LENGTH_LARGE
    : LAYOUT.CARD.METHOD_LENGTH;
  const trimMobileLength = large
    ? LAYOUT.CARD.MOBILE_METHOD_LENGTH_LARGE
    : LAYOUT.CARD.MOBILE_METHOD_LENGTH;

  const handleViewDetail = () => {
    if (onAdView) {
      onAdView(get(item, 'id'));
    } else {
      const slug = get(item, 'slug.name');
      let url = ROUTER.CUSTOMER.DEAL_DETAIL;
      if (slug) {
        url = `${url}/${slug}`;
      } else {
        const title = get(item, 'title');
        const id = get(item, 'id');
        url = `${url}/?${sanitizeUrl(title)}?${id}`;
      }
      navigate(url);
    }
  };

  return (
    <HomeGrid className={cardStyle}>
      <HomeGrid fluid className={s.header}>
        <HomeGrid className={classNames(s.pictureWrap, large && s.pictureWrapLarge)}>
          <CustomImage
            src={resolveImageUrl(dealPicture)}
            alt={get(item, 'title')}
            className={dealPicture ? s.picture : `${s.picture} ${s.pictureDefault}`}
          />
        </HomeGrid>
        <HomeGrid className={s.brand}>
          <HomeGrid fluid justify="space-between" alignItems="flex-start">
            <HomeGrid className={classNames(s.logoWrap, large && s.logoWrapLarge)}>
              {brandLogo && (
                <CustomImage
                  logo
                  src={resolveImageUrl(brandLogo)}
                  alt={brandName}
                  className={s.logo}
                />
              )}
              {!brandLogo && <Logo className={s.iconLogo} />}
            </HomeGrid>
            <HomeGrid>
              <Typo className={s.brandName}>
                {trim(
                  brandName,
                  desktop ? LAYOUT.CARD.TITLE_LENGTH_MD : LAYOUT.CARD.MOBILE_BRAND_SM,
                ) || LAYOUT.BRAND}
              </Typo>
            </HomeGrid>
          </HomeGrid>
        </HomeGrid>
      </HomeGrid>
      <HomeGrid fluid direction="column" className={s.content}>
        <HomeGrid className={s.title}>
          <Typo
            className={classNames(s.titleText, s.titleTextMd, large && s.titleTextLg)}
          >
            {large
              ? trim(
                  dealTitle,
                  desktop
                    ? LAYOUT.CARD.TITLE_LENGTH_LARGE
                    : LAYOUT.CARD.MOBILE_TITLE_LARGE,
                )
              : trim(
                  dealTitle,
                  desktop ? LAYOUT.CARD.TITLE_LENGTH : LAYOUT.CARD.MOBILE_TITLE_SM,
                )}
          </Typo>
        </HomeGrid>
        <HomeGrid className={s.types}>
          <HomeGrid
            key={uuid()}
            fluid
            justify="flex-start"
            alignItems="center"
            className={s.typeRow}
          >
            {types.map(type => (
              <Typo key={type.id} className={s.typeItem}>
                {trim(type.name, LAYOUT.CARD.TYPE_LENGTH)}
              </Typo>
            ))}
          </HomeGrid>
        </HomeGrid>
        <HomeGrid className={s.methods}>
          <HomeGrid className={s.iconText}>
            <img src={PaymentIcon} alt="Payment" className={s.icon} />
            <Typo className={classNames(s.itemTextMd, large && s.itemText)}>
              {trim(
                `${methods.map(method => ` ${method.name}`)}`,
                desktop ? trimLength : trimMobileLength,
              )}
            </Typo>
          </HomeGrid>
        </HomeGrid>
        <HomeGrid fluid justify="space-between" alignItems="center">
          <HomeGrid className={s.iconText}>
            <img src={TimerIcon} alt="Schedule" className={s.icon} />
            <Typo className={classNames(s.itemTextMd, large && s.itemText)}>
              {LAYOUT.CARD.DUE_TEXT} {moment(expire).format(TIME.DATE.FORMAT)}
            </Typo>
          </HomeGrid>
          {moment(expire).diff(moment(), 'd') < TIME.EXPIRED_WARN && (
            <HomeGrid className={s.expireWarning}>
              <Typo
                className={classNames(s.itemTextMd, s.warning, large && s.itemText)}
              >
                {dateDeadline(expire)}
              </Typo>
            </HomeGrid>
          )}
        </HomeGrid>
      </HomeGrid>
      <HomeGrid className={s.cta} onClick={handleViewDetail}>
        <Typo className={s.ctaText}>
          {LAYOUT.CARD.CTA_TEXT}
          <ChevronRight className={s.iRight} />
        </Typo>
      </HomeGrid>
    </HomeGrid>
  );
};

DealCard.propTypes = {
  item: objectOf(any).isRequired,
  large: bool,
  onAdView: func,
};

DealCard.defaultProps = {
  large: false,
  onAdView: null,
};

export default DealCard;
