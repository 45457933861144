/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Call from '../../svgs/ic-call-end.svg';
import SOCIAL_LINKS from '../../constants/socials';
import LAYOUT from '../../constants/layout';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: theme.palette.background.default,
    borderBottom: `${theme.spacing(0.125)}px solid ${theme.palette.grey[300]}`,
  },
  hotLine: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body2.fontSize,
    margin: theme.spacing(0, 1),
  },
  hotLineText: {
    display: 'none',
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fill: theme.palette.grey[900],
    margin: theme.spacing(1, 1),
    display: 'flex',
  },
  iconCall: {
    fill: theme.palette.primary.dark,
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    margin: 0,
  },
  iconWrap: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    overflow: 'hidden',
    borderRadius: theme.spacing(0.25),
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  content: {
    margin: '0 auto',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: theme.spacing(120),
  },
  [theme.breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
    content: {
      padding: theme.spacing(1, 0),
    },
    hotLineText: {
      display: 'inline',
    },
  },
}));

const Header = () => {
  const s = useStyles();

  return (
    <Grid
      component="section"
      container
      direction="row"
      className={s.root}
      alignItems="center"
    >
      <Grid
        component="div"
        container
        justify="space-evenly"
        alignItems="center"
        className={s.content}
      >
        <Grid component="div" item>
          <Grid component="div" container justify="center" alignItems="center">
            <Grid component="div" item>
              <Call className={`${s.icon} ${s.iconCall}`} />
            </Grid>
            <Grid component="div" item>
              <Typography className={s.hotLine}>
                <span className={s.hotLineText}>Đường dây nóng</span> *6868 hoặc (028)
                38 999 666
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid component="div" item>
          <Grid component="div" container justify="center" alignItems="center">
            {SOCIAL_LINKS.map(social => (
              <Grid component="div" item key={social.url}>
                <a href={social.url} target="_blank" rel="noopener noreferrer">
                  <social.Icon className={s.icon} />
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
