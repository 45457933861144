import React from 'react';
import { node } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Seo from './seo';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: theme.palette.background.default,
  },
}));

const Layout = ({ children }) => {
  const s = useStyles();

  return (
    <Grid component="div" container direction="column" className={s.root}>
      <Seo siteTitle="HC-ZONE" />
      {children}
    </Grid>
  );
};

Layout.propTypes = {
  children: node.isRequired,
};

export default Layout;
