import React, { forwardRef } from 'react';
import { bool, string, node } from 'prop-types';
import Grid from '@material-ui/core/Grid';

const HomeGrid = forwardRef((props, ref) => {
  const { type, fluid, children, ...rest } = props;
  return (
    <Grid ref={ref} component={type} container={fluid} item={!fluid} {...rest}>
      {children}
    </Grid>
  );
});

HomeGrid.propTypes = {
  fluid: bool,
  type: string,
  children: node,
};

HomeGrid.defaultProps = {
  fluid: false,
  type: 'div',
  children: null,
};

export default HomeGrid;
