import React, { useState, useEffect } from 'react';
import { arrayOf, any, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import get from 'lodash/get';
import Typo from '@material-ui/core/Typography';
import IRight from '../../svgs/ic_right.svg';
import ILeft from '../../svgs/ic_left_red.svg';
import ISearch from '../../svgs/ic_search.svg';
import IClear from '../../svgs/ic_clear.svg';
import ITick from '../../svgs/ic_tick.svg';
import HomeGrid from '../HomeGrid';
import { mobileFilters } from './props';
import COLORS from '../../constants/colors';
import LAYOUT from '../../constants/layout';
import { deAccent } from '../../utils/string';
import { DEFAULT_WARD } from '../../constants/filters';

const useStyles = makeStyles(
  ({ spacing, palette, typography, zIndex, shape, transitions }) => ({
    root: {
      position: 'relative',
      margin: spacing(0),
      display: 'none',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'auto',
      padding: spacing(0.625, 1.5, 0.625, 2),
      boxSizing: 'border-box',
      borderRadius: shape.round,
      border: `0.5px solid ${COLORS.LIGHT_GREY}`,
    },
    rootShow: {
      display: 'flex',
    },
    label: {
      paddingRight: spacing(0.5),
      fontSize: typography.body2.fontSize,
      fontWeight: typography.fontWeightBold,
    },
    iRight: {
      width: spacing(3),
      height: spacing(3),
      fill: palette.primary.main,
    },
    iBack: {
      width: spacing(3),
      height: spacing(3),
      position: 'absolute',
      top: '50%',
      left: spacing(2),
      transform: 'translateY(-50%)',
    },
    header: {
      position: 'relative',
      display: 'flex',
      margin: spacing(3.5, 0, 2),
      justifyContent: 'center',
      width: '100%',
    },
    title: {
      fontWeight: typography.fontWeightBold,
    },
    headCta: {
      position: 'absolute',
      top: '50%',
      right: spacing(2),
      transform: 'translateY(-50%)',
      fontSize: typography.body3.fontSize,
      color: palette.primary.main,
      textTransform: 'uppercase',
    },
    iSearch: {
      width: spacing(3),
      height: spacing(3),
      margin: spacing(0, 1.5, 0, 0.5),
    },
    icon: {
      width: 0,
      height: spacing(3),
      fill: palette.primary.main,
      overflow: 'hidden',
      transition: transitions.easing.custom,
    },
    iconShow: {
      width: spacing(3),
    },
    searchWrap: {
      width: '100%',
      padding: spacing(0, 2),
    },
    searchBox: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: spacing(1, 0, 2),
      padding: spacing(0.625, 1),
      border: `0.5px solid ${COLORS.LIGHT_GREY}`,
      borderRadius: shape.round,
    },
    input: {
      width: '90%',
      border: 'unset',
      outline: 'unset',
      '&::placeholder': {
        fontSize: typography.body2.fontSize,
      },
    },
    content: {
      marginLeft: spacing(2),
      height: 'calc(100vh - 120px)',
      overflow: 'auto',
      padding: spacing(0, 0, 2),
    },
    wards: {
      position: 'fixed',
      height: 0,
      top: 0,
      left: 0,
      width: '100%',
      bottom: 0,
      overflow: 'hidden',
      background: palette.background.paper,
      transition: transitions.easing.custom,
    },
    wardsShow: {
      height: '100vh',
      zIndex: zIndex.tooltip,
    },
    items: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(1.5, 2, 1.5, 0),
      borderBottom: `0.5px solid ${COLORS.LIGHT_GREY}`,
    },
    activeItem: {
      fontWeight: typography.fontWeightBold,
    },
  }),
);

const MobileWard = ({
  filteredLocation,
  onShowWard,
  filteredWard,
  wardList,
  onWard,
  showWard,
  submitWard,
  onShowLocationList,
}) => {
  const s = useStyles(undefined);
  const [toggleWardList, setToggleWardList] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState(wardList);
  const [currentLocation, setCurrentLocation] = useState(filteredLocation);
  const [currentWard, setCurrentWard] = useState([]);

  useEffect(() => {
    setCurrentLocation(filteredLocation);
  }, [filteredLocation]);

  useEffect(() => {
    setCurrentWard(filteredWard);
  }, [filteredWard]);

  useEffect(() => {
    setSearchResult(wardList);
  }, [wardList]);

  const handleToggleList = () => {
    setToggleWardList(!toggleWardList);
  };

  const handleClearSearch = () => {
    setSearchText('');
    setSearchResult(wardList);
  };

  const handleSearchLocation = evt => {
    const {
      target: { value },
    } = evt;
    if (value) {
      const searchPat = new RegExp(deAccent(value), 'i');
      setSearchResult(wardList.filter(item => searchPat.test(deAccent(item.name))));
    } else {
      setSearchResult(wardList);
    }
    setSearchText(value);
  };

  const handleSelectWard = item => () => {
    setCurrentWard([item]);
    onWard([item]);
    submitWard([item]);
    handleToggleList();
    handleClearSearch();
  };

  const handleShowLocationList = () => {
    onShowWard(false);
    handleToggleList();
    onShowLocationList(true);
  };

  return (
    <>
      <HomeGrid
        className={classNames(s.root, showWard && s.rootShow)}
        onClick={handleToggleList}
      >
        <Typo className={s.label}>{get(currentWard, '0.name', DEFAULT_WARD.name)}</Typo>
        <IRight className={s.iRight} />
      </HomeGrid>{' '}
      <HomeGrid className={classNames(s.wards, toggleWardList && s.wardsShow)}>
        <HomeGrid className={s.header}>
          <ILeft className={s.iBack} onClick={handleShowLocationList} />
          <Typo className={s.title}>
            {get(currentLocation, '0.name', DEFAULT_WARD.name)}
          </Typo>
          <Typo className={s.headCta} onClick={handleToggleList}>
            {LAYOUT.SEARCH_CLOSE_TEXT}
          </Typo>
        </HomeGrid>
        <HomeGrid className={s.searchWrap}>
          <HomeGrid className={s.searchBox}>
            <ISearch className={s.iSearch} />
            <input
              placeholder={LAYOUT.SEARCH_LOCATION_PLACEHOLDER}
              className={s.input}
              onChange={handleSearchLocation}
              value={searchText}
            />
            <IClear
              className={classNames(s.icon, searchText && s.iconShow)}
              onClick={handleClearSearch}
            />
          </HomeGrid>
        </HomeGrid>
        <HomeGrid className={s.content}>
          {searchResult.map(item => {
            const activeItem = get(filteredWard, '0.id', '0') === item.id;
            return (
              <HomeGrid
                key={item.id}
                component="div"
                item
                className={s.items}
                onClick={handleSelectWard(item)}
              >
                <Typo className={classNames(activeItem && s.activeItem)}>
                  {item.name}
                </Typo>
                <ITick className={classNames(s.icon, activeItem && s.iconShow)} />
              </HomeGrid>
            );
          })}
        </HomeGrid>
      </HomeGrid>
    </>
  );
};

MobileWard.propTypes = {
  filteredLocation: arrayOf(any),
  onShowWard: func.isRequired,
  filteredWard: arrayOf(any),
  wardList: arrayOf(any),
  onWard: func.isRequired,
  submitWard: func.isRequired,
  onShowLocationList: func.isRequired,
  showWard: bool,
};

MobileWard.defaultProps = {
  filteredLocation: [],
  filteredWard: [],
  wardList: [],
  showWard: false,
};

export default connect(mobileFilters.mapStateToProps)(MobileWard);
