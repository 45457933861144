export default {
  HC: 'homecredit.vn',
  DEAL: {
    LIST: 'Danh sách các ưu đãi',
  },
  CREATE: {
    TITLE: 'Tạo Ưu đãi',
    HEADING: 'Thông tin Ưu đãi',
    CONTENT: 'Chúc mừng! Bạn đã tạo thành công Ưu đãi mới trên hệ thống.',
    CANCEL: 'Bạn có muốn hủy bỏ Ưu đãi này?',
    ERROR: {
      SPECIAL_CHAR: 'Lỗi ký tự đặc biệt',
      SHORT: 'Lỗi độ dài tên quá ngắn hoặc ký tự đặc biệt',
      FORMAT: 'Lỗi sai định dạng',
      REQUIRED: 'là bắt buộc',
      SIZE: 'Kích thước ảnh phải nhỏ hơn 5MB',
    },
  },
  CREATE_BRAND: {
    LIST_TITLE: 'Danh sách Thương hiệu',
    TITLE: 'Thông tin thương hiệu',
    MESSAGE: 'Tạo Thương hiệu thành công! Bạn có muốn tiếp tục tạo Thương hiệu khác?',
    UPDATE:
      'Cập nhật Thương hiệu thành công. Bạn có muốn tiếp tục tạo Thương hiệu khác?',
    PROMPT_DEL_TITLE: 'Xóa Thương Hiệu',
    PROMPT_DEL_MSG: `Các Ưu đãi và Chi nhánh dưới tên Thương hiệu này sẽ bị xoá theo.
     Bạn có muốn xóa Thương hiệu này không?`,
    DELETED_MSG: 'Bạn vừa xóa một Thương Hiệu khỏi danh sách.',
    COUNT: 'Chi nhánh:',
  },
  CREATE_BRANCH: {
    LIST_TITLE: 'Danh sách Chi Nhánh',
    TITLE: 'Thông tin chi nhánh',
    MESSAGE: 'Tạo Chi nhánh thành công! Bạn có muốn tiếp tục tạo Chi nhánh khác?',
    UPDATE: 'Cập nhật Chi nhánh thành công. Bạn có muốn tiếp tục tạo Chi nhánh khác?',
    PROMPT_DEL_TITLE: 'Xóa Chi Nhánh',
    PROMPT_DEL_MSG:
      'Các Ưu đãi dưới Chi nhánh này sẽ bị xoá theo. ' +
      'Bạn có muốn xóa Chi nhánh này không?',
    DELETED_MSG: 'Bạn vừa xóa một Chi nhánh khỏi danh sách.',
  },
  UPDATE: {
    TITLE: 'Cập nhật Ưu đãi',
    CONTENT: 'Bạn đã cập nhật thông tin mới cho Ưu đãi trên hệ thống thành công!',
    CANCEL: 'Bạn có muốn hủy thay đổi thông tin Ưu đãi này?',
  },
  DELETE: {
    TITLE: 'Xóa Ưu đãi',
    CONTENT: 'Bạn đã xóa thành công Ưu đãi này trong danh sách Ưu đãi!',
    PROMPT: 'Bạn có thực sự muốn xóa Ưu đãi này ra khỏi danh sách không?',
  },
  COPY: {
    TITLE: 'Sao chép Ưu đãi',
    CONTENT: 'Bạn đã hủy thành công sao chép Ưu đãi này!',
    CANCEL: 'Bạn có muốn hủy bỏ sao chép ưu đãi mới này?',
    SUCCESS: 'Bạn đã sao chép thành công Ưu đãi mới trên hệ thống.',
  },
  BRAND: {
    DUPLICATED: 'Nhãn tên này đã tồn tại',
    TITLE: 'Tạo thương hiệu/Chi nhánh',
    TITLE_UPDATE: 'Cập nhật Thương hiệu/Chi nhánh',
    CONTENT: 'Tạo mới thành công. Bạn có muốn tạo Thương hiệu/Chi nhánh khác?',
    UPDATE: 'Cập nhật Thương hiêu/Chi nhánh thành công.',
    UPDATE_CANCEL: 'Bạn có muốn hủy cập nhật Thương hiệu/Chi nhánh này?',
    TITLE_DELETE: 'Xóa Thương hiệu/Chi nhánh',
    DELETE_CONTENT: 'Bạn có muốn xóa Thương hiệu/Chi nhánh này?',
  },
  MODAL: {
    TITLE: 'Thông Báo',
    CONTENT: 'Home Credit Việt Nam Chúc quý khách một ngày tốt lành!',
  },
  500: 'Hệ thống đang bảo trì hoặc thao tác dữ liệu chưa đúng. Vui lòng thử lại sau.',
  501: 'Tên đăng nhập/mật khẩu không đúng. Vui lòng thử lại',
  401: 'Không có quyền truy cập vào thông tin này!',
  403: 'Bạn chưa có quyền truy cập vào tài nguyên này. Vui lòng liên hệ Quản trị viên!',
  404: 'Hệ thống không tìm thấy dữ liệu cần thao tác! Vui lòng thử lại sau!',
  409: 'Thông tin này đã tồn tại trên hệ thống. Vui lòng thử lại!',
};
