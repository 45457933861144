import React, { useState, useEffect } from 'react';
import { arrayOf, any, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import get from 'lodash/get';
import Typo from '@material-ui/core/Typography';
import IRight from '../../svgs/ic_right.svg';
import ISearch from '../../svgs/ic_search.svg';
import IClear from '../../svgs/ic_clear.svg';
import HomeGrid from '../HomeGrid';
import { mobileFilters } from './props';
import { DEFAULT_ID, DEFAULT_LOCATION } from '../../constants/filters';
import { deAccent } from '../../utils/string';
import COLORS from '../../constants/colors';
import LAYOUT from '../../constants/layout';

const useStyles = makeStyles(
  ({ spacing, shape, typography, palette, zIndex, transitions }) => ({
    root: {
      position: 'relative',
      margin: spacing(0),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'auto',
      padding: spacing(0.625, 1.5, 0.625, 2),
      boxSizing: 'border-box',
      borderRadius: shape.round,
      border: `0.5px solid ${COLORS.LIGHT_GREY}`,
      transition: transitions.easing.custom,
    },
    rootHide: {
      display: 'none',
    },
    label: {
      paddingRight: spacing(0.5),
      fontSize: typography.body2.fontSize,
      fontWeight: typography.fontWeightBold,
      transition: transitions.easing.custom,
    },
    icon: {
      width: spacing(3),
      height: spacing(3),
      transform: 'rotate(90deg)',
      transition: transitions.easing.custom,
    },
    iOpen: {
      transform: 'rotate(-90deg)',
    },
    header: {
      position: 'relative',
      margin: spacing(3.5, 0, 2),
      width: '100%',
      textAlign: 'center',
    },
    title: {
      fontWeight: typography.fontWeightBold,
    },
    headCta: {
      position: 'absolute',
      top: '50%',
      right: spacing(2),
      transform: 'translateY(-50%)',
      fontSize: typography.body3.fontSize,
      color: palette.primary.main,
      textTransform: 'uppercase',
    },
    iSearch: {
      width: spacing(3),
      height: spacing(3),
      margin: spacing(0, 1.5, 0, 0.5),
    },
    clearSearch: {
      width: 0,
      height: spacing(3),
      fill: palette.primary.main,
      overflow: 'hidden',
      transition: transitions.easing.custom,
    },
    clearShow: {
      width: spacing(3),
    },
    searchWrap: {
      width: '100%',
      padding: spacing(0, 2),
    },
    searchBox: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: spacing(1, 0, 2),
      padding: spacing(0.625, 1),
      border: `0.5px solid ${COLORS.LIGHT_GREY}`,
      borderRadius: shape.round,
    },
    input: {
      width: '90%',
      border: 'unset',
      outline: 'unset',
      '&::placeholder': {
        fontSize: typography.body2.fontSize,
      },
    },
    content: {
      marginLeft: spacing(2),
      height: 'calc(100vh - 120px)',
      overflow: 'auto',
      padding: spacing(0, 0, 2),
    },
    locations: {
      position: 'fixed',
      height: 0,
      top: 0,
      left: 0,
      width: '100%',
      bottom: 0,
      overflow: 'hidden',
      background: palette.background.paper,
      transition: transitions.easing.custom,
    },
    locationsShow: {
      height: '100vh',
      zIndex: zIndex.tooltip,
    },
    items: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(1.5, 2, 1.5, 0),
      borderBottom: `0.5px solid ${COLORS.LIGHT_GREY}`,
    },
    activeItem: {
      fontWeight: typography.fontWeightBold,
    },
  }),
);

const MobileLocation = ({
  locations,
  filteredLocation,
  onLocation,
  onWard,
  onWardList,
  onShowWard,
  showWard,
  submitLocation,
  showLocationList,
}) => {
  const s = useStyles(undefined);
  const [toggleLocationList, setToggleList] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState(locations);
  const [currentLocation, setCurrentLocation] = useState(filteredLocation);

  useEffect(() => {
    setCurrentLocation(filteredLocation);
  }, [filteredLocation]);

  useEffect(() => {
    setSearchResult(locations);
  }, [locations]);

  useEffect(() => {
    setToggleList(showLocationList);
  }, [showLocationList]);

  const handleToggleList = () => {
    setToggleList(!toggleLocationList);
  };

  const handleClearSearch = () => {
    setSearchText('');
    setSearchResult(locations);
  };

  const handleSearchLocation = evt => {
    const {
      target: { value },
    } = evt;
    if (value) {
      const searchPat = new RegExp(deAccent(value), 'i');
      setSearchResult(locations.filter(item => searchPat.test(deAccent(item.name))));
    } else {
      setSearchResult(locations);
    }
    setSearchText(value);
  };

  const handleSelectLocation = item => () => {
    const subList = get(item, 'wards', []);
    const wardListEnabled = subList.length > 0;
    onLocation([item]);
    submitLocation([item]);
    if (wardListEnabled) {
      onWardList(subList);
      onShowWard(wardListEnabled);
    } else {
      onWardList([]);
      onWard([]);
    }
    handleClearSearch();
    setCurrentLocation([item]);
    handleToggleList();
  };

  return (
    <>
      <HomeGrid
        className={classNames(s.root, showWard && s.rootHide)}
        onClick={handleToggleList}
      >
        <Typo className={s.label}>
          {get(currentLocation, '0.name', DEFAULT_LOCATION.name)}
        </Typo>
        <IRight className={classNames(s.icon, toggleLocationList && s.iOpen)} />
      </HomeGrid>
      <HomeGrid
        className={classNames(s.locations, toggleLocationList && s.locationsShow)}
      >
        <HomeGrid className={s.header}>
          <Typo className={s.title}>{LAYOUT.SEARCH_LOCATION_TITLE}</Typo>
          <Typo className={s.headCta} onClick={handleToggleList}>
            {LAYOUT.SEARCH_CLOSE_TEXT}
          </Typo>
        </HomeGrid>
        <HomeGrid className={s.searchWrap}>
          <HomeGrid className={s.searchBox}>
            <ISearch className={s.iSearch} />
            <input
              placeholder={LAYOUT.SEARCH_LOCATION_PLACEHOLDER}
              className={s.input}
              onChange={handleSearchLocation}
              value={searchText}
            />
            <IClear
              className={classNames(s.clearSearch, searchText && s.clearShow)}
              onClick={handleClearSearch}
            />
          </HomeGrid>
        </HomeGrid>
        <HomeGrid className={s.content}>
          {searchResult.map(item => {
            const activeItem = get(filteredLocation, '0.id', DEFAULT_ID) === item.id;
            return (
              <HomeGrid
                key={item.id}
                className={s.items}
                onClick={handleSelectLocation(item)}
              >
                <Typo className={classNames(activeItem && s.activeItem)}>
                  {item.name}
                </Typo>
                <IRight />
              </HomeGrid>
            );
          })}
        </HomeGrid>
      </HomeGrid>
    </>
  );
};

MobileLocation.propTypes = {
  locations: arrayOf(any),
  filteredLocation: arrayOf(any),
  onLocation: func.isRequired,
  onWardList: func.isRequired,
  onWard: func.isRequired,
  showWard: bool,
  onShowWard: func.isRequired,
  submitLocation: func.isRequired,
  showLocationList: bool,
};

MobileLocation.defaultProps = {
  locations: [],
  filteredLocation: [],
  showWard: false,
  showLocationList: false,
};

export default connect(mobileFilters.mapStateToProps)(MobileLocation);
