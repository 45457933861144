/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { arrayOf, any, func, bool } from 'prop-types';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { navigate } from 'gatsby';
import { uuid } from 'uuidv4';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import resolveImageUrl from '../../utils/image';
import CustomImage from '../customImage';
import ROUTER from '../../constants/router';
import LAYOUT from '../../constants/layout';
import COLORS from '../../constants/colors';
import { searchBarProps } from './props';

const useStyles = makeStyles(
  ({ spacing, palette, typography, shape, breakpoints, transitions }) => ({
    root: {
      margin: spacing(0),
      position: 'absolute',
      height: 0,
      width: '100vw',
      transition: transitions.easing.custom,
      top: '100%',
      left: 0,
      overflow: 'hidden',
    },
    showRoot: {
      padding: spacing(1, 2),
      minHeight: spacing(30),
      height: '100vh',
      background: palette.background.paper,
    },
    brands: {
      display: 'flex',
      width: '100%',
      overflow: 'auto',
      padding: spacing(1),
      borderBottom: `1px solid ${palette.grey[200]}`,
    },
    brandItem: {
      marginRight: spacing(3),
      width: spacing(7),
    },
    brandImg: {
      width: spacing(6),
      minHeight: spacing(6),
      height: spacing(6),
      overflow: 'hidden',
    },
    brandName: {
      fontSize: typography.body3.fontSize,
      width: spacing(6),
    },
    deals: {
      display: 'flex',
      overflow: 'auto',
      margin: spacing(2, 0),
      height: 'calc(100vh - 250px)',
      justifyContent: 'space-between',
    },
    dealItem: {
      minWidth: '45%',
      width: '45%',
      margin: spacing(2, 0),
      display: 'flex',
      flexDirection: 'column',
    },
    dealImg: {
      width: '100%',
      minHeight: spacing(9),
      height: spacing(9),
    },
    dealName: {
      margin: spacing(1, 0),
      width: '100%',
      fontSize: typography.body3.fontSize,
      textAlign: 'center',
    },
    bold: {
      fontWeight: typography.fontWeightBold,
    },
    [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
      root: {
        height: 0,
        width: '100%',
        transition: transitions.easing.custom,
        top: '170%',
      },
      showRoot: {
        height: spacing(LAYOUT.SEARCH_FLYING_HEIGHT),
        minHeight: spacing(LAYOUT.SEARCH_FLYING_HEIGHT),
        boxShadow: COLORS.SHADOW_STANDARD,
        borderRadius: shape.rectRadius,
      },
      deals: {
        height: spacing(LAYOUT.SEARCH_LOCATION_WIDTH),
        minHeight: spacing(LAYOUT.SEARCH_LOCATION_WIDTH),
        justifyContent: 'flex-start',
      },
      dealItem: {
        minWidth: '23%',
        width: '23%',
        margin: spacing(2, 0),
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
      },
    },
  }),
);

const MobileFlying = ({ searchBarDeals, onClose, open }) => {
  const s = useStyles(undefined);
  const [enableResult, setEnableResult] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [dealList, setDealList] = useState([]);

  useEffect(() => {
    setEnableResult(open && enableResult);
  }, [open]);

  useEffect(() => {
    const enableList = searchBarDeals.length > 0;
    setEnableResult(open && enableList);
    if (enableList) {
      const bList = searchBarDeals.map(item => {
        const logo = get(item, 'brand.logo', null);
        const name = get(item, 'brand.name', '');
        const id = get(item, 'brand.id', '');
        return { name, logo, id };
      });
      const dList = searchBarDeals.map(item => {
        const title = get(item, 'title', '');
        const picture = get(item, 'picture', '');
        const slug = get(item, 'slug', {});
        const brandName = get(item, 'brand.name', '');
        const id = get(item, 'id');
        return { title, picture, brandName, id, slug };
      });
      setBrandList(uniqBy(bList, 'id'));
      setDealList(dList);
    } else {
      setBrandList([]);
      setDealList([]);
    }
  }, [searchBarDeals]);

  const handleViewDetail = slug => () => {
    onClose();
    navigate(`${ROUTER.CUSTOMER.DEAL_DETAIL}/${slug}`);
  };

  return (
    <Grid
      component="div"
      item
      className={classNames(s.root, enableResult && s.showRoot)}
    >
      {enableResult && (
        <>
          <Grid component="div" item className={s.brands}>
            {brandList.map(item => (
              <Grid key={uuid()} component="div" item className={s.brandItem}>
                <CustomImage
                  logo
                  src={resolveImageUrl(get(item, 'logo.url', ''))}
                  alt="logo brand"
                  className={s.brandImg}
                />
                <Tooltip title={item.name}>
                  <Typography noWrap className={s.brandName}>
                    {item.name}
                  </Typography>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
          <Grid component="div" container spacing={2} className={s.deals}>
            {dealList.map(item => {
              const title = get(item, 'title');
              const slug = get(item, 'slug.name', '');
              return (
                <Grid
                  key={uuid()}
                  component="div"
                  item
                  className={s.dealItem}
                  onClick={handleViewDetail(slug)}
                >
                  <CustomImage
                    src={resolveImageUrl(get(item, 'picture.url', ''))}
                    alt="deal picture"
                    className={s.dealImg}
                  />
                  <Typography noWrap className={classNames(s.dealName, s.bold)}>
                    {title}
                  </Typography>
                  <Typography noWrap className={s.dealName}>
                    {get(item, 'brandName')}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </Grid>
  );
};

MobileFlying.propTypes = {
  searchBarDeals: arrayOf(any),
  onClose: func.isRequired,
  open: bool,
};

MobileFlying.defaultProps = {
  searchBarDeals: [],
  open: false,
};

export default connect(searchBarProps.mapStateToProps)(MobileFlying);
