import axios from 'axios';
import URL from '../constants/url';

const config = axios.create({
  baseURL: URL.HOST,
  responseType: 'json',
});
config.defaults.baseURL = URL.HOST;
config.defaults.headers.post['Content-Type'] = 'application/json';
config.defaults.headers.post.Accept = 'application/json';
config.defaults.headers.get.Accept = 'application/json';
config.defaults.headers.get['Content-Type'] = 'application/json';

export default config;
