export default {
  LABELS: {
    SAVE: 'Lưu',
    UPDATE: 'Cập nhật',
    COPY: 'Sao chép',
    CANCEL: 'Hủy',
    CREATE: 'Tạo mới',
    DELETE: 'Xóa',
    AGREE: 'Đồng ý',
    NEXT: 'Tiếp theo',
    BACK: 'Trước',
    DONE: 'Hoàn tất',
    LOGIN: 'Đăng Nhập',
  },
};
