import React from 'react';
import { string, func, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import Typo from '@material-ui/core/Typography';
import HomeGrid from './HomeGrid';
import MESSAGE from '../constants/messages';
import CTA from '../constants/cta';

const useStyles = makeStyles(({ spacing, palette, typography, shape }) => ({
  root: {
    position: 'fixed',
    overflow: 'hidden',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    background: palette.background.default,
    maxWidth: spacing(40),
    borderRadius: shape.rectRadius,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: palette.primary.dark,
    padding: spacing(0.5, 1),
    color: palette.primary.contrastText,
  },
  icon: {
    width: spacing(3),
    height: spacing(3),
    fill: palette.grey[50],
  },
  content: {
    background: palette.background.default,
    minHeight: spacing(10),
    padding: spacing(1, 1, 0),
  },
  message: {
    width: '100%',
    margin: spacing(0, 1),
    textAlign: 'justify',
  },
  ctaWrap: {
    margin: spacing(1, 0, 0.5),
  },
  cta: {
    fontSize: typography.body2.fontSize,
    padding: spacing(0.5, 2),
    borderRadius: shape.rectRadius,
  },
}));

const HomeModal = ({ title, message, onConfirm, onClose, isOpen }) => {
  const s = useStyles();

  return (
    <Modal open={isOpen}>
      <HomeGrid fluid direction="column" className={s.root}>
        <HomeGrid className={s.title}>
          <Typo variant="body2">{title.toUpperCase()}</Typo>
          {typeof onClose === 'function' && (
            <IconButton href="" onClick={onClose} size="small">
              <Clear className={s.icon} />
            </IconButton>
          )}
        </HomeGrid>
        <HomeGrid fluid alignItems="center" className={s.content}>
          <Typo variant="body2" className={s.message}>
            {message}
          </Typo>
          <HomeGrid fluid justify="center" className={s.ctaWrap}>
            {typeof onConfirm === 'function' && (
              <Button href="" className={s.cta} onClick={onConfirm}>
                {CTA.LABELS.AGREE}
              </Button>
            )}
          </HomeGrid>
        </HomeGrid>
      </HomeGrid>
    </Modal>
  );
};

HomeModal.propTypes = {
  onConfirm: func,
  onClose: func,
  title: string,
  message: string,
  isOpen: bool.isRequired,
};

HomeModal.defaultProps = {
  onConfirm: null,
  onClose: null,
  title: MESSAGE.MODAL.TITLE,
  message: MESSAGE.MODAL.CONTENT,
};

export default HomeModal;
