import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';

export default {
  TRANSPARENT: 'transparent',
  NONE: 'transparent',
  WHITE: '#FFFFFF',
  SMOKE: grey[200],
  LIGHT_GREY: grey[300],
  GREY: grey[500],
  DARK_GREY: grey[700],
  BLACK: grey[900],
  ERROR: red[500],
  SHADOW_STANDARD: '0 4px 16px rgba(124, 133, 152, 0.15)',
  SHADOW_MEDIUM: '2px 2px 8px 4px #ccc',
  TEXT_SHADOW: `2px 1px 8px ${grey[800]}`,
  OVERLAY: 'rgba(247, 247, 247, 0.4)',
  LINEAR_BG: 'linear-gradient(to right, #f5f5f5, #e0e0e0, #e0e0e0, #f5f5f5)',
};
