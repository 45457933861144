/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { objectOf, any, func, arrayOf, bool } from 'prop-types';
import { connect } from 'react-redux';
import { navigate, Link } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import get from 'lodash/get';
import noop from 'lodash/noop';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../../svgs/logo_v.svg';
import ISearch from '../../svgs/ic_search.svg';
import Clear from '../../svgs/ic_clear.svg';
import COLORS from '../../constants/colors';
import ROUTER from '../../constants/router';
import MobileLocation from './mobileLocation';
import MobileWard from './mobileWard';
import MobileFlying from './mobileFlying';
import LAYOUT from '../../constants/layout';
import { FILTER_KEY, DEFAULT_ID } from '../../constants/filters';
import { mobileFilters } from './props';

const useStyles = makeStyles(
  ({ spacing, palette, typography, shape, transitions }) => ({
    root: {
      position: 'relative',
      width: '100%',
      margin: spacing(0),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing(2.5, 1),
      background: palette.background.paper,
    },
    logo: {
      width: spacing(10),
      transition: transitions.easing.custom,
    },
    logoHide: {
      width: 0,
      overflow: 'hidden',
    },
    iSearch: {
      border: `0.5px solid ${COLORS.LIGHT_GREY}`,
      padding: spacing(1),
      borderRadius: shape.round,
      width: spacing(4.5),
      height: spacing(4.5),
      margin: spacing(0, 1),
    },
    icon: {
      width: spacing(3),
      height: spacing(3),
    },
    iClear: {
      fill: palette.primary.main,
    },
    btnSearch: {
      padding: 0,
      display: 'none',
    },
    btnSearchShow: {
      display: 'block',
    },
    searchBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: spacing(0.625, 1),
      border: `0.5px solid ${COLORS.LIGHT_GREY}`,
      borderRadius: shape.round,
      transition: transitions.easing.custom,
    },
    searchBoxHide: {
      width: 0,
      overflow: 'hidden',
      border: 'unset',
    },
    input: {
      border: 'unset',
      outline: 'unset',
      width: '85%',
      fontSize: typography.body2.fontSize,
    },
    showInput: {
      display: 'block',
    },
    cta: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: spacing(28),
      height: spacing(4.5),
      overflow: 'hidden',
      opacity: 1,
      transition: transitions.easing.custom,
    },
    ctaHide: {
      width: 0,
      height: 0,
      opacity: 0,
    },
  }),
);

const SEARCH_ID = 'mobile-search';

const MobileSearch = ({
  flying,
  configs,
  searchBarDeals,
  submitLocation,
  submitWard,
  filteredLocation,
  filteredWard,
  dispatchQuickSearch,
  dispatchSubmitQuickSearch,
  dispatchClearQuickSearch,
  dispatchWardList,
  dispatchFilteredWard,
  dispatchFilteredLocation,
  dispatchPageNumber,
  onHome,
}) => {
  const s = useStyles();
  const [toggleSearch, setToggleSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { LOCATION, BRAND, DISTRICT } = FILTER_KEY;
  const [locations, setLocations] = useState([]);
  const [showWard, setShowWard] = useState(false);
  const [searchLocationId, setLocationId] = useState('');
  const [searchWardId, setWardId] = useState('');
  const [showLocationList, setShowLocationList] = useState(false);
  const [submittedSearchBarDeals, setSubmittedSearchBarDeals] = useState([]);
  const [enableClearSearch, setEnableClearSearch] = useState(false);

  useEffect(() => {
    setLocations(get(configs, LOCATION, []));
  }, [configs]);

  useEffect(() => {
    const locationId = get(filteredLocation, '0.id', DEFAULT_ID);
    if (locationId !== DEFAULT_ID) {
      setLocationId(locationId);
    } else {
      setLocationId('');
    }
  }, [filteredLocation]);

  useEffect(() => {
    const wardId = get(filteredWard, '0.id', '');
    setWardId(wardId);
    setShowWard(Boolean(wardId));
  }, [filteredWard]);

  useEffect(() => {
    setSubmittedSearchBarDeals(searchBarDeals);
    setEnableClearSearch(Boolean(searchBarDeals.length));
  }, [searchBarDeals]);

  useEffect(() => {
    setEnableClearSearch(Boolean(searchText.length));
  }, [searchText]);

  const handleToggleSearch = () => {
    if (!toggleSearch) {
      document.getElementById(SEARCH_ID).focus();
    }
    setToggleSearch(!toggleSearch);
    setSearchText('');
  };

  const handleSearch = search => {
    let searchInput = search;
    if (!search) {
      searchInput = searchText;
    }
    if (searchInput) {
      const searchCriteria = {
        [BRAND]: searchInput,
        [LOCATION]: searchLocationId,
        [DISTRICT]: searchWardId,
      };
      const searchString = Object.keys(searchCriteria).reduce((acc, key) => {
        let str = acc;
        if (searchCriteria[key]) {
          str += `&${key}=${searchCriteria[key]}`;
        }
        return str;
      }, '?');
      dispatchQuickSearch(searchString);
      dispatchPageNumber(LAYOUT.INIT_PAGE);
    }
  };

  const handleSubmitSearch = evt => {
    if (evt) {
      evt.preventDefault();
    }
    if (searchText.length > 2) {
      handleToggleSearch();
      dispatchSubmitQuickSearch(submittedSearchBarDeals);
      navigate(ROUTER.CUSTOMER.SEARCH_DEAL_RESULTS, { replace: true });
    }
  };

  const handleSearchText = evt => {
    const {
      target: { value },
    } = evt;
    if (value || value === '') {
      setSearchText(value);
      if (value.length > 2) {
        handleSearch(value);
      }
    }
  };

  const handleClearSearch = () => {
    handleToggleSearch();
    if (enableClearSearch && flying) {
      dispatchClearQuickSearch();
    }
  };

  const handleShowWard = value => {
    setShowWard(value);
    setShowLocationList(!value);
  };

  const handleShowLocationList = value => {
    setShowLocationList(value);
  };

  return (
    <Grid component="div" item className={s.root}>
      <Link to={ROUTER.CUSTOMER.ROOT} onClick={onHome}>
        <Logo className={classNames(s.logo, toggleSearch && s.logoHide)} />
      </Link>
      <Grid
        component="form"
        item
        onSubmit={handleSubmitSearch}
        className={classNames(s.searchBox, !toggleSearch && s.searchBoxHide)}
      >
        <IconButton
          type="submit"
          className={classNames(s.btnSearch, toggleSearch && s.btnSearchShow)}
        >
          <ISearch className={classNames(s.icon, searchText && s.iClear)} />
        </IconButton>
        <input
          id={SEARCH_ID}
          placeholder="Tìm thương hiệu, ưu đãi"
          className={s.input}
          onChange={handleSearchText}
          value={searchText}
        />
        <Clear className={classNames(s.icon, s.iClear)} onClick={handleClearSearch} />
      </Grid>
      <Grid
        component="div"
        item
        className={classNames(s.cta, toggleSearch && s.ctaHide)}
      >
        <ISearch className={s.iSearch} onClick={handleToggleSearch} />
        <Grid component="div" item>
          <MobileLocation
            locations={locations}
            onLocation={dispatchFilteredLocation}
            onWard={dispatchFilteredWard}
            onWardList={dispatchWardList}
            onShowWard={handleShowWard}
            showWard={showWard}
            submitLocation={submitLocation}
            showLocationList={showLocationList}
          />
          <MobileWard
            showWard={showWard}
            onShowWard={handleShowWard}
            onShowLocationList={handleShowLocationList}
            onWard={dispatchFilteredWard}
            submitWard={submitWard}
          />
        </Grid>
      </Grid>
      <MobileFlying onClose={handleClearSearch} open={toggleSearch} />
    </Grid>
  );
};

MobileSearch.propTypes = {
  flying: bool,
  configs: objectOf(any),
  filteredLocation: arrayOf(any),
  filteredWard: arrayOf(any),
  searchBarDeals: arrayOf(any),
  dispatchFilteredLocation: func.isRequired,
  dispatchFilteredWard: func.isRequired,
  dispatchWardList: func.isRequired,
  submitLocation: func,
  submitWard: func,
  dispatchQuickSearch: func.isRequired,
  dispatchSubmitQuickSearch: func.isRequired,
  dispatchClearQuickSearch: func.isRequired,
  dispatchPageNumber: func.isRequired,
  onHome: func,
};

MobileSearch.defaultProps = {
  flying: true,
  configs: {},
  filteredLocation: [],
  searchBarDeals: [],
  filteredWard: [],
  submitLocation: noop,
  submitWard: noop,
  onHome: noop,
};

export default connect(
  mobileFilters.mapStateToProps,
  mobileFilters.mapDispatchToProps,
)(MobileSearch);
