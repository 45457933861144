import get from 'lodash/get';
import {
  dealDetailApi,
  setSearchBarDeals,
  pageNumberAction,
  searchTextAction,
  setUserDealsFiltered,
  setSameCategoryDeals,
  setUserDealsSearched,
  clearUserDealsSearched,
  submitUserDealsSearched,
} from '../../actions/deal';

import {
  setFilteredSort,
  setFilteredLocation,
  setFilteredWard,
  setWardList,
  setFilteredItemsString,
  setFilteredSortString,
  setFilteredLocationString,
  setFilteredWardString,
} from '../../actions/filters';

export const dealProps = {
  mapStateToProps: ({ customer }) => ({
    deal: customer.dealDetail,
  }),
  mapDispatchToProps: dispatch => ({
    dispatchDealDetail: payload => dispatch(dealDetailApi(payload)),
  }),
};

export const categoryProps = {
  mapStateToProps: ({ customer }) => ({
    dealCategory: customer.dealCategory,
  }),
  mapDispatchToProps: dispatch => ({
    dispatchSameCategory: payload => dispatch(setSameCategoryDeals(payload)),
  }),
};

export const searchBarProps = {
  mapStateToProps: ({ customer, deal }) => ({
    searchBarDeals: customer.searchBarDeals,
    submittedSearchBarDeals: customer.submittedSearchBarDeals,
    searchText: customer.searchText,
    configs: deal.configs,
  }),
  mapDispatchToProps: dispatch => ({
    dispatchSetSearchBarDeals: payload => dispatch(setSearchBarDeals(payload)),
    dispatchSearchText: payload => dispatch(searchTextAction(payload)),
  }),
};

export const mobileFilters = {
  mapStateToProps: ({ filters, deal, customer }) => ({
    searchBarDeals: customer.searchBarDeals,
    configs: deal.configs,
    filteredLocation: filters.filteredLocation,
    filteredWard: filters.filteredWard,
    wardList: filters.wardList,
    filteredItemsString: get(filters, 'filteredItemsString', ''),
  }),
  mapDispatchToProps: dispatch => ({
    dispatchFilteredLocation: payload => dispatch(setFilteredLocation(payload)),
    dispatchFilteredWard: payload => dispatch(setFilteredWard(payload)),
    dispatchWardList: payload => dispatch(setWardList(payload)),
    dispatchQuickSearch: payload => dispatch(setUserDealsSearched(payload)),
    dispatchSubmitQuickSearch: payload => dispatch(submitUserDealsSearched(payload)),
    dispatchClearQuickSearch: () => dispatch(clearUserDealsSearched()),
    dispatchPageNumber: payload => dispatch(pageNumberAction(payload)),
  }),
};

export default {
  mapStateToProps: ({ customer, deal, filters }) => ({
    dealCount: customer.dealCount,
    searchDealResults: customer.searchDealResults,
    configs: deal.configs,
    // refactor code
    filteredSort: get(filters, 'filteredSort', 0),
    filteredLocation: get(filters, 'filteredLocation', []),
    filteredWard: get(filters, 'filteredWard', []),
    filteredItemsString: get(filters, 'filteredItemsString', ''),
    filteredSortString: get(filters, 'filteredSortString', ''),
    filteredLocationString: get(filters, 'filteredLocationString', ''),
    filteredWardString: get(filters, 'filteredWardString', ''),
    pageNumber: get(customer, 'pageNumber', 1),
  }),
  mapDispatchToProps: dispatch => ({
    dispatchPageNumber: payload => dispatch(pageNumberAction(payload)),
    // refactor code
    dispatchFilteredSort: payload => dispatch(setFilteredSort(payload)),
    dispatchFilteredLocation: payload => dispatch(setFilteredLocation(payload)),
    dispatchFilteredWard: payload => dispatch(setFilteredWard(payload)),
    dispatchWardList: payload => dispatch(setWardList(payload)),
    dispatchSetUserDealsFiltered: payload => dispatch(setUserDealsFiltered(payload)),
    dispatchSetFilteredItemsString: payload =>
      dispatch(setFilteredItemsString(payload)),
    dispatchSetFilteredSortString: payload => dispatch(setFilteredSortString(payload)),
    dispatchSetFilteredLocationString: payload =>
      dispatch(setFilteredLocationString(payload)),
    dispatchSetFilteredWardString: payload => dispatch(setFilteredWardString(payload)),
  }),
};
