import { resetError } from '../actions/common';

export const errorProps = {
  mapStateToProps: ({ common }) => ({
    isError: common.isError,
    errMsg: common.errMsg,
  }),
  mapDispatchToProps: dispatch => ({
    dispatchResetError: () => dispatch(resetError()),
  }),
};
