import ACTIONS from '../constants/actions';
import createAction from './createAction';

export const setFilteredLocation = createAction(
  ACTIONS.CUSTOMER.SET_FILTERED_LOCATION,
  true,
);

export const setFilteredWard = createAction(ACTIONS.CUSTOMER.SET_FILTERED_WARD, true);

export const setWardList = createAction(ACTIONS.CUSTOMER.SET_WARD_LIST, true);

export const setFilteredSort = createAction(ACTIONS.CUSTOMER.SET_FILTERED_SORT, true);

export const setFilteredItemsString = createAction(
  ACTIONS.CUSTOMER.FILTERED_ITEMS_STRING,
  true,
);

export const setFilteredSortString = createAction(
  ACTIONS.CUSTOMER.FILTERED_SORT_STRING,
  true,
);

export const setFilteredLocationString = createAction(
  ACTIONS.CUSTOMER.FILTERED_LOCATION_STRING,
  true,
);

export const setFilteredWardString = createAction(
  ACTIONS.CUSTOMER.FILTERED_WARD_STRING,
  true,
);
