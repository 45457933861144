/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash/get';
import Typo from '@material-ui/core/Typography';
import SOCIAL_LINKS from '../constants/socials';
import LAYOUT from '../constants/layout';
import COLORS from '../constants/colors';
import AppStore from '../images/app-store-badge-us-white.png';
import GooglePlay from '../images/google-play-badge-us.png';
import HcMark from '../images/hcvn.png';
import HomeGrid from './HomeGrid';

const useStyles = makeStyles(
  ({ spacing, typography, palette, breakpoints, zIndex }) => ({
    root: {
      margin: spacing(0),
    },
    content1: {
      position: 'relative',
      background: palette.primary.dark,
      overflow: 'hidden',
    },
    homeBG: {
      position: 'absolute',
      bottom: '-10%',
      left: '10%',
      height: '100%',
      zIndex: 0,
    },
    content2: {
      maxWidth: spacing(120),
      padding: spacing(3, 0),
      margin: '0 auto',
    },
    footTop: {
      maxWidth: spacing(120),
      padding: spacing(4.5, 0, 6),
      margin: '0 auto',
      zIndex: 1,
    },
    icon: {
      width: spacing(3),
      height: spacing(3),
      margin: spacing(0, 1),
    },
    title: {
      fontWeight: typography.fontWeightBold,
      fontSize: typography.h5.fontSize,
      padding: spacing(0, 1, 3),
      color: palette.primary.contrastText,
      textAlign: 'center',
    },
    textColumn: {
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(0, 2),
      width: 'auto',
    },
    textTitle: {
      fontWeight: typography.fontWeightBold,
      fontSize: typography.body1.fontSize,
      color: palette.primary.contrastText,
    },
    subText: {
      margin: spacing(1, 0, 4),
      fontSize: typography.body1.fontSize,
      color: palette.primary.contrastText,
    },
    ctaWrap: {
      width: spacing(38),
      background: 'white',
      borderRadius: spacing(7),
      padding: spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cta: {
      textDecoration: 'none',
      fontSize: spacing(1.8),
      textTransform: 'uppercase',
      color: palette.text.primary,
      fontWeight: typography.fontWeightBold,
      zIndex: 1,
    },
    socialBar: {
      flexDirection: 'column',
      padding: spacing(0, 3, 0),
    },
    downloadApp: {
      width: '100%',
      display: 'flex',
      padding: spacing(1, 0, 3),
      justifyContent: 'space-between',
      borderBottom: `1px solid ${COLORS.LIGHT_GREY}`,
      marginBottom: spacing(3),
    },
    socialLinks: {
      display: 'flex',
    },
    textFooter: {
      textAlign: 'left',
      fontSize: spacing(1.8),
      color: palette.text.primary,
    },
    addressBar: {
      padding: spacing(2, 3, 2),
    },
    addressBarAdmin: {
      position: 'relative',
      padding: spacing(2, 2, 4),
      background: palette.primary.dark,
      overflow: 'hidden',
    },
    textFooterWrap: {
      maxWidth: spacing(LAYOUT.MAX_WIDTH),
      margin: '0 auto',
      zIndex: zIndex.appBar,
    },
    HcMark: {
      position: 'absolute',
      height: '120%',
      top: spacing(-1),
      right: 0,
      zIndex: zIndex.mobileStepper,
    },
    footerAdmin: {
      color: COLORS.WHITE,
    },
    companyLabel: {
      fontWeight: typography.fontWeightBold,
      marginBottom: spacing(1),
    },
    [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
      title: {
        margin: spacing(0, 0, 3),
        fontSize: typography.h4.fontSize,
      },
      textColumn: {
        width: '28%',
        padding: 'unset',
      },
      textTitle: {
        minHeight: spacing(6),
      },
      addressBar: {
        padding: spacing(4, 0, 2),
      },
      socialBar: {
        flexDirection: 'row',
        padding: spacing(0, 0, 3),
        borderBottom: `${spacing(0.125)}px solid ${palette.grey[200]}`,
      },
      downloadApp: {
        flexDirection: 'row',
        width: '35%',
        padding: 'unset',
        justifyContent: 'space-between',
        borderBottom: 'unset',
        marginBottom: 'unset',
      },
      homeBG: {
        top: '-30%',
        right: 0,
        left: 'unset',
        bottom: 'unset',
        zIndex: 0,
        height: 'auto',
      },
    },
  }),
);

const Footer = ({ cta }) => {
  const s = useStyles();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            appUrl
            xsellUrl
          }
        }
      }
    `,
  );

  const appUrl = get(site, 'siteMetadata.appUrl');
  const xsellUrl = get(site, 'siteMetadata.xsellUrl');

  return (
    <HomeGrid fluid direction="column" className={s.root}>
      {cta && (
        <>
          <HomeGrid fluid direction="column" className={s.content1}>
            <img src={HcMark} alt="Home Background" className={s.homeBG} />
            <HomeGrid className={s.footTop}>
              <HomeGrid fluid direction="column" justify="center" alignItems="center">
                <HomeGrid fluid justify="center" alignItems="center">
                  <Typo className={s.title}>
                    Tận hưởng vô vàn tiện ích và ưu đãi hấp dẫn khi sử dụng thẻ tín dụng
                    và ứng dụng Home Credit.
                  </Typo>
                  <HomeGrid fluid justify="space-between">
                    <HomeGrid className={s.textColumn}>
                      <Typo variant="body2" className={s.textTitle}>
                        Ưu đãi hoàn tiền
                      </Typo>
                      <Typo className={s.subText}>
                        Tiền hoàn sẽ được ghi nhận vào tài khoản chính của chủ thẻ và
                        được thể hiện trên sao kê hàng tháng.
                      </Typo>
                    </HomeGrid>
                    <HomeGrid className={s.textColumn}>
                      <Typo variant="body2" className={s.textTitle}>
                        Ưu đãi giảm giá
                      </Typo>
                      <Typo className={s.subText}>
                        Chủ thẻ sẽ được nhận giảm giá trực tiếp trên hóa đơn thanh toán
                        tại cửa hàng.
                      </Typo>
                    </HomeGrid>
                    <HomeGrid className={s.textColumn}>
                      <Typo variant="body2" className={s.textTitle}>
                        Ưu đãi dành riêng cho khách hàng sử dụng ứng dụng Home Credit
                      </Typo>
                      <Typo className={s.subText}>
                        Tận hưởng những ưu đãi cực kì hấp dẫn từ các đối tác của Home
                        Credit thật dễ dàng bằng cách tìm những ưu đãi trong phương thức
                        áp dụng &quot;Dành cho khách hàng sử dụng ứng dụng Home
                        Credit&quot;.
                      </Typo>
                    </HomeGrid>
                  </HomeGrid>
                </HomeGrid>
                <a
                  href={xsellUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.cta}
                >
                  <HomeGrid className={s.ctaWrap}>
                    <Typo className={s.cta}>Đăng ký thẻ ngay</Typo>
                  </HomeGrid>
                </a>
              </HomeGrid>
            </HomeGrid>
          </HomeGrid>
          <HomeGrid fluid justify="center" alignItems="center" className={s.content2}>
            <HomeGrid
              fluid
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              <HomeGrid fluid justify="space-between" className={s.socialBar}>
                <a
                  href={appUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={s.downloadApp}
                >
                  <HomeGrid>
                    <img src={AppStore} alt="App Store" />
                  </HomeGrid>
                  <HomeGrid>
                    <img src={GooglePlay} alt="G-Play" />
                  </HomeGrid>
                </a>
                <HomeGrid className={s.socialLinks}>
                  {SOCIAL_LINKS.map(social => (
                    <HomeGrid key={social.url}>
                      <a href={social.url} target="_blank" rel="noopener noreferrer">
                        <social.Icon className={s.icon} />
                      </a>
                    </HomeGrid>
                  ))}
                </HomeGrid>
              </HomeGrid>
            </HomeGrid>
            <HomeGrid fluid direction="column" className={s.addressBar}>
              <HomeGrid>
                <Typo className={`${s.textFooter} ${s.companyLabel}`}>
                  &copy; {new Date().getFullYear()} Bản quyền thuộc về Home Credit
                </Typo>
              </HomeGrid>
              <HomeGrid>
                <Typo className={s.textFooter}>
                  CÔNG TY TÀI CHÍNH TNHH MTV HOME CREDIT VIỆT NAM | Tầng G, 8 và 10 Tòa
                  nhà Phụ Nữ, Số 20 Nguyễn Đăng Giai, Phường Thảo Điền, TP.Thủ Đức,
                  TP.HCM.
                </Typo>
              </HomeGrid>
            </HomeGrid>
          </HomeGrid>
        </>
      )}
      {!cta && (
        <HomeGrid fluid direction="column" className={s.addressBarAdmin}>
          <img src={HcMark} alt="HcMark" className={s.HcMark} />
          <HomeGrid fluid className={s.textFooterWrap}>
            <HomeGrid>
              <Typo className={`${s.textFooter} ${s.companyLabel} ${s.footerAdmin}`}>
                &copy; {new Date().getFullYear()} Bản quyền thuộc về Home Credit
              </Typo>
            </HomeGrid>
            <HomeGrid>
              <Typo className={`${s.textFooter} ${s.footerAdmin}`}>
                CÔNG TY TÀI CHÍNH TNHH MTV HOME CREDIT VIỆT NAM | Tầng G, 8 và 10 Tòa
                nhà Phụ Nữ, Số 20 Nguyễn Đăng Giai, Phường Thảo Điền, TP.Thủ Đức,
                TP.HCM.
              </Typo>
            </HomeGrid>
          </HomeGrid>
        </HomeGrid>
      )}
    </HomeGrid>
  );
};

Footer.propTypes = {
  cta: bool,
};

Footer.defaultProps = {
  cta: false,
};

export default Footer;
