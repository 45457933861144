import { setLoading, setError } from './common';
import MESSAGE from '../constants/messages';
import createAction from './createAction';

export const handleRequest = async (reqFunc, args) => {
  try {
    const result = await reqFunc(...args);
    const { status, data } = result;
    if (status === 200) {
      return [data, null];
    }
    const errorMsg = data === 501 ? MESSAGE[data] : data;
    return [null, errorMsg];
  } catch (error) {
    const { message } = error;
    const code = message.replace(/\D/g, '');
    const errMsg = MESSAGE[code] || MESSAGE[500];
    return [null, errMsg];
  }
};

/*
 * Wrapping the Api request action
 * @param: {Function} dispatch
 * @param: {Function} successAction - Redux action to dispatch
 * @param: {Function} reqFunc - api request function
 * @param: {Array} args - Parameters injecting to reqFunc
 */
export const wrappedActionRequest = async (
  dispatch,
  successAction,
  reqFunc,
  ...args
) => {
  dispatch(setLoading(true));
  const [result, error] = await handleRequest(reqFunc, args);
  if (error) {
    dispatch(setError(error));
  } else {
    dispatch(successAction(result));
  }
  dispatch(setLoading(false));
};

/*
 * Create Request Action to API
 * @param: {Function} reqFunc - Api request function
 * @param: {Boolean} resData - Response data sent to redux action parameters
 * @param: {String} type - Redux action type
 */
export const createApiRequest = (reqFunc, resData, type) => (
  data,
  token,
) => async dispatch => {
  const successAction = createAction(type, resData);
  return wrappedActionRequest(dispatch, successAction, reqFunc, data, token);
};
