import LinkedIn from '../svgs/ic_linkedin.svg';
import Youtube from '../svgs/ic_youtube.svg';
import Facebook from '../svgs/ic_facebook.svg';
import Zalo from '../svgs/ic_zalo.svg';

import URL from './url';

export default [
  {
    Icon: Facebook,
    url: URL.FACEBOOK,
  },
  {
    Icon: Zalo,
    url: URL.ZALO,
  },
  {
    Icon: Youtube,
    url: URL.YOUTUBE,
  },
  {
    Icon: LinkedIn,
    url: URL.LINKED_IN,
  },
];
