/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { objectOf, any, func, arrayOf, bool } from 'prop-types';
import { connect } from 'react-redux';
import { navigate, Link } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import get from 'lodash/get';
import noop from 'lodash/noop';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../../svgs/logo_v.svg';
import ISearch from '../../svgs/ic_search.svg';
import Clear from '../../svgs/ic_clear.svg';
import COLORS from '../../constants/colors';
import ROUTER from '../../constants/router';
import DesktopLocation from './DesktopLocation';
import DesktopWard from './desktopWard';
import MobileFlying from './mobileFlying';
import LAYOUT from '../../constants/layout';
import { deAccent } from '../../utils/string';
import { FILTER_KEY, DEFAULT_ID } from '../../constants/filters';
import { mobileFilters } from './props';
import HomeGrid from '../HomeGrid';

const useStyles = makeStyles(
  ({ spacing, palette, typography, shape, transitions }) => ({
    root: {
      width: '100%',
      margin: 0,
      background: palette.background.paper,
    },
    container: {
      position: 'relative',
      maxWidth: spacing(LAYOUT.MAX_WIDTH),
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing(2.5, 1),
    },
    brand: {
      minWidth: spacing(22),
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      width: spacing(10),
      transition: transitions.easing.custom,
    },
    logoHide: {
      width: 0,
      overflow: 'hidden',
    },
    iSearch: {
      cursor: 'pointer',
      border: `0.5px solid ${COLORS.LIGHT_GREY}`,
      padding: spacing(1, 2),
      borderRadius: shape.round,
      width: spacing(4.5),
      height: spacing(4.5),
      margin: spacing(0, 1),
    },
    icon: {
      width: spacing(3),
      height: spacing(3),
    },
    iClear: {
      cursor: 'pointer',
      fill: palette.primary.main,
      margin: spacing(0, 1),
    },
    btnSearch: {
      padding: spacing(0, 1),
      display: 'block',
      '&:hover': {
        background: COLORS.NONE,
      },
    },
    btnActive: {
      fill: palette.primary.main,
    },
    searchBox: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'space-between',
      padding: spacing(1),
      borderRadius: shape.round,
      border: `0.5px solid ${COLORS.LIGHT_GREY}`,
      transition: transitions.easing.custom,
    },
    searchBoxHide: {
      width: 0,
      overflow: 'hidden',
      border: 'unset',
    },
    input: {
      border: 'unset',
      outline: 'unset',
      width: '100%',
      fontSize: typography.body1.fontSize,
    },
    showInput: {
      display: 'block',
    },
    cta: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: spacing(2),
      width: spacing(LAYOUT.SEARCH_LOCATION_WIDTH),
      transition: transitions.easing.custom,
    },
  }),
);

const SEARCH_ID = 'desktop-search';

const DesktopSearch = ({
  flying,
  configs,
  searchBarDeals,
  submitLocation,
  submitWard,
  filteredLocation,
  filteredWard,
  dispatchQuickSearch,
  dispatchSubmitQuickSearch,
  dispatchClearQuickSearch,
  dispatchWardList,
  dispatchFilteredWard,
  dispatchFilteredLocation,
  dispatchPageNumber,
  onHome,
}) => {
  const s = useStyles();
  const [searchText, setSearchText] = useState('');
  const { LOCATION, BRAND, DISTRICT } = FILTER_KEY;
  const [locations, setLocations] = useState([]);
  const [showWard, setShowWard] = useState(false);
  const [searchLocationId, setLocationId] = useState('');
  const [searchWardId, setWardId] = useState('');
  const [showLocationList, setShowLocationList] = useState(false);
  const [submittedSearchBarDeals, setSubmittedSearchBarDeals] = useState([]);
  const [enableClearSearch, setEnableClearSearch] = useState(false);

  useEffect(() => {
    setLocations(get(configs, LOCATION, []));
  }, [configs]);

  useEffect(() => {
    const locationId = get(filteredLocation, '0.id', DEFAULT_ID);
    if (locationId !== DEFAULT_ID) {
      setLocationId(locationId);
    } else {
      setLocationId('');
    }
  }, [filteredLocation]);

  useEffect(() => {
    const wardId = get(filteredWard, '0.id', '');
    setWardId(wardId);
    setShowWard(Boolean(wardId));
  }, [filteredWard]);

  useEffect(() => {
    setSubmittedSearchBarDeals(searchBarDeals);
    setEnableClearSearch(
      Boolean(searchBarDeals.length) || Boolean(searchText.length > 2),
    );
  }, [searchBarDeals]);

  useEffect(() => {
    setEnableClearSearch(Boolean(searchText.length));
  }, [searchText]);

  const handleSearch = search => {
    let searchInput = search;
    if (!search) {
      searchInput = searchText;
    }
    if (searchInput) {
      const searchCriteria = {
        [BRAND]: searchInput,
        [LOCATION]: searchLocationId,
        [DISTRICT]: searchWardId,
      };
      const searchString = Object.keys(searchCriteria).reduce((acc, key) => {
        let str = acc;
        if (searchCriteria[key]) {
          str += `&${key}=${searchCriteria[key]}`;
        }
        return str;
      }, '?');
      dispatchQuickSearch(searchString);
      dispatchPageNumber(LAYOUT.INIT_PAGE);
    }
  };

  const handleSubmitSearch = evt => {
    if (evt) {
      evt.preventDefault();
    }
    if (searchText.length > 2) {
      setSearchText('');
      setEnableClearSearch(false);
      dispatchSubmitQuickSearch(submittedSearchBarDeals);
      navigate(ROUTER.CUSTOMER.SEARCH_DEAL_RESULTS, { replace: true });
    }
  };

  const handleSearchText = evt => {
    const {
      target: { value },
    } = evt;
    if (value || value === '') {
      setSearchText(value);
      if (value.length > 3) {
        handleSearch(deAccent(value));
        setEnableClearSearch(true);
      } else {
        setEnableClearSearch(false);
      }
    }
  };

  const handleClearSearch = () => {
    if (enableClearSearch && flying) {
      dispatchClearQuickSearch();
    }
    setSearchText('');
  };

  const handleShowWard = value => {
    setShowWard(value);
    setShowLocationList(false);
  };

  const handleShowLocationList = value => {
    setShowLocationList(value);
  };

  return (
    <HomeGrid className={s.root}>
      <HomeGrid className={s.container}>
        <HomeGrid className={s.brand}>
          <Link to={ROUTER.CUSTOMER.ROOT} onClick={onHome}>
            <Logo className={s.logo} />
          </Link>
        </HomeGrid>
        <HomeGrid type="form" onSubmit={handleSubmitSearch} className={s.searchBox}>
          <IconButton type="submit" className={s.btnSearch}>
            <ISearch className={classNames(s.icon, enableClearSearch && s.btnActive)} />
          </IconButton>
          <input
            id={SEARCH_ID}
            placeholder={LAYOUT.SEARCH_PLACEHOLDER}
            className={s.input}
            onChange={handleSearchText}
            value={searchText}
          />
          {enableClearSearch && (
            <Clear
              className={classNames(s.icon, s.iClear)}
              onClick={handleClearSearch}
            />
          )}
          <MobileFlying onClose={handleClearSearch} open={Boolean(searchText)} />
        </HomeGrid>
        <HomeGrid className={s.cta}>
          <HomeGrid>
            <DesktopLocation
              locations={locations}
              onLocation={dispatchFilteredLocation}
              onWard={dispatchFilteredWard}
              onWardList={dispatchWardList}
              onShowWard={handleShowWard}
              showWard={showWard}
              submitLocation={submitLocation}
              showLocationList={showLocationList}
            />
            <DesktopWard
              showWard={showWard}
              onShowWard={handleShowWard}
              onShowLocationList={handleShowLocationList}
              onWard={dispatchFilteredWard}
              submitWard={submitWard}
            />
          </HomeGrid>
        </HomeGrid>
      </HomeGrid>
    </HomeGrid>
  );
};

DesktopSearch.propTypes = {
  flying: bool,
  configs: objectOf(any),
  filteredLocation: arrayOf(any),
  filteredWard: arrayOf(any),
  searchBarDeals: arrayOf(any),
  dispatchFilteredLocation: func.isRequired,
  dispatchFilteredWard: func.isRequired,
  dispatchWardList: func.isRequired,
  submitLocation: func,
  submitWard: func,
  dispatchQuickSearch: func.isRequired,
  dispatchSubmitQuickSearch: func.isRequired,
  dispatchClearQuickSearch: func.isRequired,
  dispatchPageNumber: func.isRequired,
  onHome: func,
};

DesktopSearch.defaultProps = {
  flying: true,
  configs: {},
  filteredLocation: [],
  searchBarDeals: [],
  filteredWard: [],
  submitLocation: noop,
  submitWard: noop,
  onHome: noop,
};

export default connect(
  mobileFilters.mapStateToProps,
  mobileFilters.mapDispatchToProps,
)(DesktopSearch);
