export default {
  GREETING: {
    MORNING: 'Chào buổi sáng',
    AFTERNOON: 'Chào buổi chiều',
    EVENING: 'Chào buổi tối',
  },
  SPLIT: {
    NOON: 12,
    NIGHT: 17,
  },
  ONE_HOUR: 60, // base on minute
  ONE_DAY: 60 * 24, // base on minute
  EXPIRED_WARN: 31, // days
  DATE: {
    FORMAT: 'DD/MM/YYYY',
    ISO: 'YYYY-MM-DD',
    ISO_LONG: 'YYYY-MM-DDTHH:mm:ss',
    NORMAL: 'DD/MM/YYYY HH:mm:ss',
  },
  EFFECT_TIMEOUT: 500,
  TRANSITION_TIMEOUT: 100,
};
