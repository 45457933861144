import TIME from './time';

export default {
  // this is the factor of 8
  APP_BAR_HEIGHT: 14,
  MAX_WIDTH: 120,
  FILTER_FLOAT_WIDTH: 145,
  FILTER_FLOAT_POSITION: 125,
  MAX_DEAL_DETAIL_WIDTH: 180,
  MAX_WIDTH_BANNER: 160,
  MAX_HEIGHT_BANNER: 80,
  MAX_HEIGHT_BANNER_MOBILE: 31,
  ADMIN_BANNER_HEIGHT: 8,
  ADMIN_FOOTER_HEIGHT: 11,
  ADMIN_TITLE_HEIGHT: 8,
  FOOTER_HEIGHT: 45,
  MOBILE_FOOTER_HEIGHT: 56,
  HEADER_SCROLL_TOP: 14,
  MOBILE_FILTER_SCROLL_TOP: 56,
  SHOW_ITEM_SEARCH: 5,
  ADMIN_PAGE_ITEM: 9,
  INPUT: {
    WIDTH: 350,
  },
  PAGE_ITEMS: 10,
  INIT_PAGE: 1,
  INIT_SORT: 0,
  PAGING: 3,
  DEAL: {
    TOP: 4,
    LARGE_CHUNK: 2,
    USUAL: 6,
    SMALL_CHUNK: 3,
  },
  BRANCH_CHUNK: 2,
  BRANCH_PER_PAGE: 6,
  SAME_CATEGORY_CHUNK: 3,
  CARD_WIDTH: 21,
  SMALL_CARD_WIDTH: 38,
  LARGE_CARD_WIDTH: 58,
  MEDIUM_CARD_WIDTH: 40,
  CARD: {
    MOBILE_TITLE_LARGE: 33,
    MOBILE_TITLE_SM: 22,
    MOBILE_BRAND_SM: 17,
    TITLE_LENGTH_SM: 10,
    TITLE_LENGTH_MD: 23,
    BRAND_LENGTH_MD: 20,
    TITLE_LENGTH_LARGE: 43,
    TITLE_LENGTH: 32,
    TYPE_LENGTH: 30,
    MOBILE_METHOD_LENGTH_LARGE: 33,
    MOBILE_METHOD_LENGTH: 22,
    METHOD_LENGTH_LARGE: 53,
    METHOD_LENGTH: 37,
    DUE_TEXT: 'Hết hạn:',
    CTA_TEXT: 'Xem chi tiết',
  },
  LOGO: {
    TITLE_LENGTH: 14,
    TITLE_LENGTH_LARGE: 40,
  },
  BANNER_SMALL_PICTURE_CHUNK: 4,
  BANNER_SLIDE_SHOW_INTERVAL: 8000, // 8sec
  PROCESS_STEPS: {
    RANGE: 3,
    FIRST: 1,
    SECOND: 2,
    FINAL: 3,
  },
  REQUEST_LIMIT: 1000000,
  SEARCH_CHAR: 1,
  BRAND: 'Home Credit',
  HOME_PAGE: 'Trang chủ',
  RESULT_PAGE: 'Kết quả tìm kiếm',
  DEAL_TEXT: 'ưu đãi',
  SEARCH_CLOSE_TEXT: 'Đóng',
  SEARCH_BRAND_PLACEHOLDER: 'Tìm thương hiệu',
  SEARCH_PLACEHOLDER: 'Tìm thương hiệu, ưu đãi',
  SEARCH_LOCATION_PLACEHOLDER: 'Tìm khu vực',
  SEARCH_LOCATION_TITLE: 'Chọn tỉnh, thành',
  SEARCH_LOCATION_WIDTH: 37,
  SEARCH_LOCATION_HEIGHT: 65,
  SEARCH_FLYING_HEIGHT: 52,
  RESPONSIVE_DESKTOP: 'md',
  TRANSITION_EASING: `all ${TIME.TRANSITION_TIMEOUT}ms ease`,
};
