export default {
  ADMIN: {
    ROOT: '/tao-uu-dai',
    get LOGIN() {
      return `${this.ROOT}`;
    },
    get DASHBOARD() {
      return '/chi-tiet-tao-uu-dai';
    },
  },
  CUSTOMER: {
    ROOT: '/',
    get DEAL_DETAIL() {
      // return '/chi-tiet-uu-dai';
      return '/chitiet';
    },
    get SEARCH_DEAL_RESULTS() {
      return '/ket-qua-tim-kiem';
    },
  },
};
