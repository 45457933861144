/* eslint-disable no-restricted-globals, no-undef */
import moment from 'moment';
import { zeroPad } from './string';
import TIME from '../constants/time';

/*
 * Sanitize the form data to matching the HTTP body POST/UPDATE request for Deal (Brand)
 * @param: { Object } values - form values from Formik
 * @return: { Object } sanitized data
 */

export const sanitizeSubmitData = values =>
  Object.keys(values)
    .map(key => {
      return { [key]: values[key].id || values[key] };
    })
    .reduce(
      (acc, next) => ({
        ...acc,
        ...next,
      }),
      {},
    );

export const dateDeadline = date => {
  const now = moment();
  const target = moment(date);
  const diffMin = target.diff(now, 'm');
  const divDay = diffMin / TIME.ONE_DAY;
  const diffDay = Math.floor(divDay);
  const divHour = (divDay - diffDay) * 24;
  const diffHour = Math.floor(divHour);
  const divMin = divHour - diffHour;
  const lastMin = Math.floor(divMin * TIME.ONE_HOUR);
  return `${zeroPad(diffDay)}d:${zeroPad(diffHour)}h:${zeroPad(lastMin)}m`;
};

export const createHeaders = token => ({
  headers: {
    Authorization: token && `Bearer ${token}`,
  },
});

export const addUrlQuery = url => history.pushState({}, document.title, url);

export const repUrlQuery = url =>
  history.replaceState(null, null, url || location.pathname);

export const sanitizeUrlQuery = url =>
  url
    .replace(/^\?$/, '')
    .replace('&&', '&')
    .replace(/&$/, '')
    .replace('?&', '?');
