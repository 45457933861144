import React from 'react';
import { string, bool } from 'prop-types';
import HCVN from '../images/defaultBanner.png';
import Logo from '../images/hclogo.png';

const CustomImage = ({ logo, src, alt, className }) => {
  return (
    <img
      onError={e => {
        e.target.onerror = null;
        e.target.src = logo ? Logo : HCVN;
      }}
      src={src}
      alt={alt}
      className={className}
    />
  );
};

CustomImage.propTypes = {
  logo: bool,
  src: string,
  alt: string,
  className: string,
};

CustomImage.defaultProps = {
  logo: false,
  src: '',
  alt: '',
  className: '',
};

export default CustomImage;
