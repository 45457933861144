/* eslint-disable no-undef */

import { useEffect } from 'react';

export default (ref, callback) => {
  const handleClickOff = evt => {
    if (ref.current && !ref.current.contains(evt.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOff);
    return () => {
      document.removeEventListener('mousedown', handleClickOff);
    };
  });
};
