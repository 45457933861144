export default {
  HOST:
    // Config URL Backend
    process.env.GATSBY_API_URL || 'https://hczone.apps-nonprod-blue.platform.hcnet.vn',
  G_PLAY:
    // eslint-disable-next-line max-len
    'https://play.google.com/store/apps/details?id=vn.homecredit.hcvn&referrer=utm_source%3Dhomecredit.vn%26utm_medium%3Dbanner%26utm_content%3Dandroid%26utm_campaign%3Dbadge_icon_website',
  APP_STORE:
    // eslint-disable-next-line max-len
    'https://click.google-analytics.com/redirect?tid=UA-55135171-3&url=https%3A%2F%2Fitunes.apple.com%2Fapp%2Fapple-store%2Fid1167077808%3Fpt%3D118406731%26ct%3Dbadge_icon_website%26mt%3D8&aid=1167077808&idfa=idfa&cs=homecredit.vn&cm=banner&cn=badge_icon_website&cc=ios',
  LINKED_IN: 'https://www.linkedin.com/company/home-credit-vietnam',
  YOUTUBE: 'https://www.youtube.com/channel/UC89SCG03O7kVb0LGyCNGhYA',
  FACEBOOK: 'https://www.facebook.com/homecreditvn',
  ZALO: 'https://zalo.me/1232799776964235050',
  AUTH: '/auth/local',
  BRAND: '/brands',
  BRANCH: '/branches',
  CONFIGS: '/deals/configs',
  DEAL: '/deals',
  DEAL_ADMIN: '/deals/admin',
  FILTER: '/deals/filter',
  SEARCH: '/deals/search',
  ADMIN_SEARCH: '/deals/adminSearch',
  SETTINGS: '/settings',
  SLUG: '/slugs',
  UPLOAD: '/upload',
  get LOGIN() {
    return this.AUTH;
  },
};
