import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { configsAction } from '../actions/deal';
import { dealConfigs } from '../api/deal';

export default () => {
  const [config, setConfig] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await dealConfigs();
      setConfig(get(response, 'data', {}));
    };
    fetchSettings();
  }, []);

  dispatch(configsAction(config));

  return config;
};
