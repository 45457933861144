import React from 'react';
import { func, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import HomeModal from './HomeModal';
import HomeGrid from './HomeGrid';
import { errorProps } from './props';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
});

const Error = ({ isError, errMsg, dispatchResetError }) => {
  const s = useStyles();

  return (
    <HomeGrid fluid justify="center" alignItems="center" className={s.root}>
      <HomeModal
        isOpen={isError}
        title="Thông Báo Lỗi!"
        message={errMsg}
        onClose={dispatchResetError}
      />
    </HomeGrid>
  );
};

Error.propTypes = {
  isError: bool,
  errMsg: string,
  dispatchResetError: func.isRequired,
};

Error.defaultProps = {
  isError: false,
  errMsg: '',
};

export default connect(
  errorProps.mapStateToProps,
  errorProps.mapDispatchToProps,
)(Error);
